import React, { useState, useEffect, useContext } from 'react';
import { Container, Tabs, Tab, Typography, Avatar } from '@mui/material';
import KYC from './KYC';
import BankDetails from './BankDetails';
import ChangePassword from './ChangePassword';
import PersonaDetails from './PersonaDetails';
import { ThemeContext } from '../../theme-context';
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    isLoading: true,
  });
  const { theme } = useContext(ThemeContext);

  const getThemeColors = () => {
    return {
      text: theme === 'dark' ? '#fff' : '#111', 
      background: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
      borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)', 
      tabActive: theme === 'dark' ? '#1a73e8' : '#1a73e8', 
      tabInactive: theme === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#000', 
      nameBackground: theme === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'transparent', 
    };
  };

  const colors = getThemeColors();

  useEffect(() => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (userId && accessToken) {
      const apiUrl = `${config.apiBaseUrl}/user/getProfile`;
      axios
        .get(apiUrl, {
          headers: {
            'user-id': userId,
            'x-access-token': accessToken,
          },
        })
        .then((response) => {
          const { first_name, last_name, user_name, isKYC } = response.data.userData;

          setUserInfo({
            firstName: first_name,
            lastName: last_name,
            userName: user_name,
            isLoading: false, 
          });
        })
        .catch((error) => {
          console.error('Error fetching profile data:', error);
          setUserInfo((prevState) => ({
            ...prevState,
            isLoading: false, 
          }));
        });
    } else {
      setUserInfo((prevState) => ({
        ...prevState,
        isLoading: false, 
      }));
    }
  }, []); 

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const displayName = userInfo.isLoading
    ? '......' 
    : `${userInfo.firstName} ${userInfo.lastName}`; 

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '0',
        height: '100%',
        overflowY: 'auto',
        margin: '0 auto',
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ position: 'relative' }}>
        <div
          style={{
            height: '200px',
            background: 'linear-gradient(135deg, #1a9988 0%, #1a4699 100%)',
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0.2,
              backgroundImage: `url('/placeholder.svg?height=200&width=1200')`,
              backgroundSize: 'cover',
            }}
          />
        </div>

        <div
          style={{
            position: 'relative',
            marginTop: '-75px',
            textAlign: 'center',
            padding: '0 16px',
          }}
        >
          <Avatar
            src="/placeholder.svg?height=100&width=100"
            sx={{
              width: 150,
              height: 150,
              margin: '0 auto',
              border: '4px solid white',
              position: 'relative',
            }}
          />
          <Typography
            variant="h5"
            style={{
              marginTop: '16px',
              color: colors.text,
              backgroundColor: colors.nameBackground,
              padding: '8px',
              borderRadius: '4px',
            }}
          >
            {displayName}
          </Typography>
        </div>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          style={{
            borderBottom: `1px solid ${colors.borderColor}`,
            marginBottom: '24px',
            width: '100%',
            overflowX: 'auto',
          }}
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="Profile" style={{ color: activeTab === 0 ? colors.tabActive : colors.tabInactive }} />
          <Tab label="KYC" style={{ color: activeTab === 1 ? colors.tabActive : colors.tabInactive }} />
          <Tab label="Bank details" style={{ color: activeTab === 2 ? colors.tabActive : colors.tabInactive }} />
          <Tab label="Change password" style={{ color: activeTab === 3 ? colors.tabActive : colors.tabInactive }} />
        </Tabs>

        {activeTab === 0 && <PersonaDetails />}
        {activeTab === 1 && <KYC />}
        {activeTab === 2 && <BankDetails />}
        {activeTab === 3 && <ChangePassword />}
      </div>
    </Container>
  );
};

export default Profile;
