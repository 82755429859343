import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Card, Typography, TextField, Grid, CardContent, Skeleton } from "@mui/material";
import { ThemeContext } from "../../theme-context";
import { ArrowBack } from "@mui/icons-material";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useWebSocketData } from "../../Context/WebSocketContext";
import Swal from "sweetalert2";


function OptionsChain() {
  const { theme } = useContext(ThemeContext);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [limit, setLimit] = useState("");
  const [orderType, setOrderType] = useState("Market");
  const [availableAmount, setAvailableAmount] = useState();
  const [showForm, setShowForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState("19 Dec");
  const { instrumentToken, exchange } = useParams();
  const [companyExchange, setCompanyExchange] = useState(null);
  const [tradingSymbol, setTradingSymbol] = useState(null);
  const [dates, setDates] = useState([]);
  const [optionsData, setOptionsData] = useState({});
  const [strikePrice, setStrikePrice] = useState(null);
  const [socketStrikePrice, setSocketStrikePrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedInstrumentToken, setSelectedInstrumentToken] = useState(null);
  const [isBuyInProgress, setIsBuyInProgress] = useState(false);
  const [isSellInProgress, setIsSellInProgress] = useState(false);
  const { socketData } = useWebSocketData();
  const [tradingSymbolFno, setTradingSymbolFno] = useState(null);


  const currentTheme = theme === "dark" ? {
    selectedDateBackground: "#f0f0f0",
    buttonBackground: "transparent",
    selectedDateTextColor: "#000",
    buttonText: "inherit",
    hoverBackground: "#f0f0f0",
  } : {
    selectedDateBackground: "#1e90ff",
    buttonBackground: "transparent",
    selectedDateTextColor: "#fff",
    buttonText: "inherit",
    hoverBackground: "#f0f0f0",
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
        const response = await axios.get(apiUrl, {
          headers: {
            "instrument-token": instrumentToken,
            "market-type": exchange,
          },
        });

        setTradingSymbol(response.data.tradingsymbol);
        fetchOptionsData(response.data.tradingsymbol);
        setStrikePrice(response.data.last_price);
      } catch (error) {
        console.error("Error fetching company details", error);
      }
    };

    const fetchAvailableAmount = async () => {
      try {
        const userToken = Cookies.get("userToken");
        const userId = Cookies.get("userId");

        const apiUrl = `${config.apiBaseUrl}/user/getAvailableBalance`;
        const response = await axios.get(apiUrl, {
          headers: {
            "user-id": userId,
            "x-access-token": userToken,
          },
        });

        if (response.status === 200) {
          setAvailableAmount(response.data.availableBalance || 0);
        } else {
          setAvailableAmount(null);
        }
      } catch (error) {
        setAvailableAmount(null);
      }
    };

    const fetchOptionsData = async (tradeSymbol) => {
      try {
        const validTradeSymbols = ["NIFTY", "FINNIFTY", "MIDCPNIFTY", "BANKNIFTY", "SENSEX"];

        let mainSymbol = tradeSymbol.split(" ")[0]; 

        if (tradeSymbol.includes("NIFTY") && tradeSymbol.includes("BANK")) {
          mainSymbol = "BANKNIFTY"; 
        } else if (tradeSymbol.includes("MIDCPNIFTY") && tradeSymbol.includes("BANK")) {
          mainSymbol = "BANKMIDCPNIFTY"; 
        } else if (tradeSymbol.includes("FINNIFTY") && tradeSymbol.includes("BANK")) {
          mainSymbol = "BANKFINNIFTY"; 
        } else if (tradeSymbol.includes("NIFTY") && tradeSymbol.includes("50")) {
          mainSymbol = "NIFTY"; 
        }

        if (!validTradeSymbols.includes(mainSymbol)) {
          Swal.fire({
            title: 'Invalid Trade Symbol!',
            text: `The trade symbol "${tradeSymbol}" is invalid. Valid options are: NIFTY, FINNIFTY, MIDCPNIFTY, BANKNIFTY, SENSEX.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
          return; 
        }

        const optionsApiUrl = `${config.apiBaseUrl}/stock/getOptionChain`;
        const response = await axios.get(optionsApiUrl, {
          headers: {
            "trade-symbol": mainSymbol, 
          },
        });

        const optionsData = response.data.data;

        
        if (optionsData && optionsData.length > 0) {
          const groupedData = optionsData.reduce((acc, option) => {
            const expiry = option.expiry;
            const strike = option.strike;
            const instrumentType = option.instrument_type;
            const lastPrice = parseFloat(option.last_price);
            const net_change = option.net_change;
            const instrument_TokenFno = option.instrument_token;
            const lot_size = option.lot_size;
            const tradingsymbol = option.tradingsymbol;
            const exchange = option.exchange;

            if (!acc[expiry]) acc[expiry] = { CE: [], PE: [], lot_size: lot_size };

            const optionData = {
              last_price: lastPrice,
              instrument_type: instrumentType,
              strike: strike,
              net_change: net_change,
              instrument_token: instrument_TokenFno,
              tradingsymbol: tradingsymbol,
              exchange: exchange,
            };

            if (instrumentType === 'CE') {
              acc[expiry].CE.push(optionData);
            } else if (instrumentType === 'PE') {
              acc[expiry].PE.push(optionData);
            }

            return acc;
          }, {});

          Object.keys(groupedData).forEach(expiry => {
            groupedData[expiry].CE.sort((a, b) => a.strike - b.strike);
            groupedData[expiry].PE.sort((a, b) => a.strike - b.strike);
          });

          setOptionsData(groupedData);
          const expiryDates = Object.keys(groupedData);
          setDates(expiryDates.slice(0, 5));

          if (expiryDates.length > 0) {
            setSelectedDate(expiryDates[0]);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
    fetchAvailableAmount();
  }, [instrumentToken, exchange]);

  useEffect(() => {
    if (socketData && Object.keys(socketData).length > 0) {
      const updatedOptionsData = { ...optionsData };

      const strikeToken = instrumentToken;
      if (strikeToken && socketData[strikeToken]) {
        const strikeInfo = socketData[strikeToken];
        setSocketStrikePrice(strikeInfo.last_price);
      }

      Object.keys(updatedOptionsData).forEach(expiry => {
        ['CE', 'PE'].forEach(type => {
          updatedOptionsData[expiry][type].forEach(option => {
            const socketInfo = socketData[option.instrument_token];
            if (socketInfo) {
              option.last_price = socketInfo.last_price;
              option.net_change = socketInfo.net_change;
            }
          });
        });
      });

      setOptionsData(updatedOptionsData);
    }
  }, [socketData]);

  const handleBuy = async () => {
    setIsBuyInProgress(true);
    try {
      const latestPrice = orderType === "Market" ? selectedPrice?.price : limit;
      // const requiredAmount = quantity * lotSize * latestPrice;
      const requiredAmount =  latestPrice;
      const quantitywithlot = quantity * lotSize;

      if (availableAmount < requiredAmount) {
        Swal.fire({
          title: 'Insufficient Balance',
          text: 'Please add funds to proceed with the purchase.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return; 
      }

      if (quantity <= 0) {
        Swal.fire({
          title: 'Error!',
          text: 'Quantity must be greater than 0 to place a buy order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      

      const orderData = {
        userId: userId,
        instrument_token: selectedInstrumentToken,
        symbol: tradingSymbolFno,
        orderType: orderType.toLowerCase(),
        quantity: quantitywithlot,
        price: requiredAmount,  
        tradeType: 'option',
        stockType : companyExchange,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/buy`, orderData, {
        headers: {
          'x-access-token': userToken,
        },
      });

      if (response && response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message || 'Order placed successfully!',  
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: `Unexpected status code: ${response?.status || 'No status'} `, 
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 428) {
          Swal.fire({
            title: 'Insufficient Stock!',
            text: 'You do not have enough stock to complete this transaction.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.message || 'An unexpected error occurred. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Runtime Error!',
          text: error.message || 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }finally{
      setIsBuyInProgress(false);
    }
  };


  const handleSell = async () => {
    setIsSellInProgress(true);
    try {
      const latestPrice = orderType === "Market" ? selectedPrice?.price : limit;
      // const requiredAmount = quantity * lotSize * latestPrice;
      const requiredAmount =  latestPrice;
      const quantitywithlot = quantity * lotSize;

      if (quantity <= 0) {
        Swal.fire({
          title: 'Error!',
          text: 'Quantity must be greater than 0 to place a sell order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        return;
      }

      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      
      const orderData = {
        userId: userId,
        instrument_token: selectedInstrumentToken,
        symbol: tradingSymbolFno,
        orderType: orderType.toLowerCase(),
        quantity: quantitywithlot,
        price: requiredAmount,  
        tradeType: 'option',
        stockType : companyExchange,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/sell`, orderData, {
        headers: {
          'x-access-token': userToken,
        },
      });

      if (response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message || 'Order placed successfully!',  
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: `Unexpected status code: ${response?.status || 'No status'}`, 
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 428) {
          Swal.fire({
            title: 'Insufficient Stock!',
            text: 'You do not have enough stock to complete this transaction.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.message || 'An unexpected error occurred. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Runtime Error!',
          text: error.message || 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }finally{
      setIsSellInProgress(false);
    }
  };


  const getCurrentStrikeRange = (optionsData, selectedDate, currentStrikePrice) => {
    if (!optionsData[selectedDate]) return null;

    const callStrikes = optionsData[selectedDate].CE.map((call) => call.strike);
    const putStrikes = optionsData[selectedDate].PE.map((put) => put.strike);
    
    const strikePriceList = Array.from(new Set([...callStrikes, ...putStrikes])).sort((a, b) => a - b);

    for (let i = 0; i < strikePriceList.length - 1; i++) {
      const min = strikePriceList[i];
      const max = strikePriceList[i + 1];

      if (currentStrikePrice >= min && currentStrikePrice <= max) {
        return { 
          min, 
          max, 
          aboveIndex: i,  
          belowIndex: i + 1 
        };
      }
    }

    return null;
  };

  const selectedExpiryData = optionsData[selectedDate];
  const lotSize = selectedExpiryData ? selectedExpiryData.lot_size : 0;

  return (
    <Card sx={{ p: 2, height: "500px", display: "flex", flexDirection: "column", backgroundColor: theme === "dark" ? "#181818" : "#edebeb", color: theme === "dark" ? "#fff" : "#000" }}>
      {showForm && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              startIcon={<ArrowBack />}
              onClick={() => setShowForm(false)}
              sx={{ color: theme === "dark" ? "#fff" : "#000", padding: "5px", minWidth: "unset", cursor:'pointer' }}
            />
            <Typography variant="h6" sx={{ fontWeight: "bold", marginLeft: "10px" }}>
              {tradingSymbolFno}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", fontSize: "20px", fontWeight: "bold", paddingLeft: "20px" }}>
            ₹{selectedPrice?.price}
          </Box>
        </Box>
      )}
      
      {!showForm && (
        <>
          <Box display="flex" gap={1} mb={2} flexWrap="nowrap" position="sticky" top={0} bgcolor={currentTheme.backgroundColor} zIndex={1} height={40}>
            {dates.map((date) => {
              const formattedDate = new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });

              return (
                <Button
                  key={date}
                  variant={date === selectedDate ? "contained" : "outlined"}
                  size="small"
                  onClick={() => setSelectedDate(date)} 
                  sx={{
                    fontSize: '10px',
                    padding: '4px 8px',
                    minWidth: '60px',
                    flexGrow: 1,
                    backgroundColor: date === selectedDate ? currentTheme.selectedDateBackground : currentTheme.buttonBackground,
                    color: date === selectedDate ? currentTheme.selectedDateTextColor : currentTheme.buttonText,
                    '&:hover': {
                      backgroundColor: currentTheme.selectedDateBackground,
                    },
                  }}
                >
                  {formattedDate}
                </Button>
              );
            })}
          </Box>
          <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
            {loading ? (
              <Box>
                <Skeleton variant="text" width="100%" height={50} sx={{ marginBottom: 1 }} />
                <Skeleton variant="text" width="100%" height={50} sx={{ marginBottom: 1 }} />
                <Skeleton variant="text" width="100%" height={50} sx={{ marginBottom: 1 }} />
              </Box>
            ) : (
              <>
                <Box display="flex" fontSize="10px" fontWeight="bold" mb={1}>
                  <Box sx={{ width: "33%", textAlign: "left" }}>Call Price</Box>
                  <Box sx={{ width: "33%", textAlign: "center" }}>Strike Price</Box>
                  <Box sx={{ width: "33%", textAlign: "right" }}>Put Price</Box>
                </Box>
                <Box>
                  {optionsData[selectedDate] && optionsData[selectedDate].CE && optionsData[selectedDate].PE ? (
                    optionsData[selectedDate].CE.map((callOption, index) => {
                      const correspondingPut = optionsData[selectedDate].PE.find(
                        (putOption) => putOption.strike === callOption.strike
                      );

                      const currentRange = getCurrentStrikeRange(optionsData, selectedDate, socketStrikePrice ?? strikePrice);

                      return (
                        <Box
                          key={index}
                          display="flex"
                          fontSize="10px"
                          py={0.5}
                        >
                          {/* Call Price */}
                          <Box
                            sx={{ width: "33%", cursor: "pointer" }}
                            onClick={() => {
                              setSelectedInstrumentToken(callOption.instrument_token);
                              setSelectedPrice({ type: "call", price: callOption.last_price });
                              setCompanyExchange(callOption.exchange);
                              setTradingSymbolFno(callOption.tradingsymbol);
                              setShowForm(true);
                            }}
                          >
                            <Typography variant="body2">₹{callOption.last_price}</Typography>
                            <Typography
                              variant="caption"
                              color={callOption.net_change < 0 ? "error.main" : "success.main"}
                            >
                              {typeof callOption.net_change === 'number' ? callOption.net_change.toFixed(2) : 'N/A'}
                            </Typography>
                          </Box>

                          <Box
                            key={index}
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <Typography variant="body1" fontSize="16px" fontWeight="bold">
                              {callOption.strike}
                            </Typography>

                          {currentRange && index === currentRange.aboveIndex && (
                            <Box 
                              sx={{ 
                                marginTop: "10px", 
                                padding: "5px 10px",  
                                backgroundColor: "#1e90ff", 
                                borderRadius: "50px", 
                                display: "flex",  
                                justifyContent: "center", 
                                alignItems: "center",
                                width: "80%", 
                                height: "15px",  
                                marginLeft: "auto", 
                                marginRight: "auto",
                                position: "relative",
                                '@media (max-width: 1250px)': {
                                  width: "80%",  
                                },
                                '&::before': {
                                  content: '""',
                                  position: "absolute",
                                  left: "-45%", 
                                  top: "50%", 
                                  width: "45%", 
                                  height: "2px", 
                                  backgroundColor: "#1e90ff",
                                  transform: "translateY(-50%)", 
                                },
                                '&::after': {
                                  content: '""',
                                  position: "absolute",
                                  right: "-45%", 
                                  top: "50%", 
                                  width: "45%", 
                                  height: "2px",
                                  backgroundColor: "#1e90ff",
                                  transform: "translateY(-50%)", 
                                },
                              }}
                            >
                              <Typography variant="body2" sx={{ color: "white", fontSize: "14px" }}>
                                ₹{socketStrikePrice ?? strikePrice}
                              </Typography>
                            </Box>
                          )}
                          </Box>

                          {/* Put Price */}
                          <Box
                            sx={{ width: "33%", cursor: "pointer", textAlign: "right",marginRight: "5px" }}
                            onClick={() => {
                              if (correspondingPut) {
                                setSelectedInstrumentToken(correspondingPut.instrument_token);
                                setSelectedPrice({ type: "put", price: correspondingPut.last_price });
                                setCompanyExchange(correspondingPut.exchange);
                                setTradingSymbolFno(correspondingPut.tradingsymbol);
                                setShowForm(true);
                              }
                            }}
                          >
                            {correspondingPut ? (
                              <Typography variant="body2">₹{correspondingPut.last_price}</Typography>
                            ) : (
                              <Typography variant="body2">N/A</Typography>
                            )}
                            <Typography variant="caption" color={correspondingPut.net_change < 0 ? "error.main" : "success.main"}>
                              {typeof correspondingPut?.net_change === 'number' ? correspondingPut.net_change.toFixed(2) : 'N/A'}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })
                  ) : null}
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      {showForm && (
        <CardContent
          style={{
            backgroundColor: theme === "dark" ? "#181818" : "#edebeb",
            color: theme === "dark" ? "#fff" : "#000",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
          }}
        >
          {/* Quantity Input */}
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"}>
                Quantity
              </Typography>
              <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"} align="right">
                1 lot x {lotSize}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <TextField
                type="text"  
                value={quantity === "" ? "" : quantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || /^[0-9]*$/.test(value)) {
                    setQuantity(value);
                  }
                }}
                variant="outlined"
                fullWidth
                style={{
                  borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                  backgroundColor: theme === "dark" ? "#333" : "transparent",
                  height: "40px",
                }}
                InputProps={{
                  style: {
                    color: theme === "dark" ? "white" : "black",
                    height: "100%",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: theme === "dark" ? "white" : "black",
                  },
                }}
              />
              <Button
                variant="outlined"
                style={{
                  marginLeft: "8px",
                  color: theme === "dark" ? "white" : "black",
                  borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                  height: "40px", 
                  minWidth: "40px",
                  padding: "0 10px", 
                  }}
                onClick={() => setQuantity(quantity === "" ? 1 : Number(quantity) + 1)}
                >
                +
              </Button>
              </Box>
            </Box>

          {/* Price Section */}
          <Box mt={4}>
            <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"}>
              Price
            </Typography>
            <TextField
              value={orderType === "Limit" ? (limit || selectedPrice?.price) : selectedPrice?.price} 
              variant="outlined"
              fullWidth
              onChange={(e) => orderType === "Limit" && setLimit(e.target.value)}  
              disabled={orderType === "Market"}  
              style={{
                borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                backgroundColor: theme === "dark" ? "#333" : "transparent", 
                height: "40px",
              }}
              InputProps={{
                style: {
                  color: theme === "dark" ? "white" : "black",
                  height: "40px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: theme === "dark" ? "white" : "black",
                  height: "100%",
                },
              }}
            />
          </Box>

          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: orderType === "Market" ? "#1976d2" : "transparent",
                    color: orderType === "Market" ? "#fff" : (theme === "dark" ? "#fff" : "#000"),
                    borderColor: orderType === "Market" ? "#1976d2" : (theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)"),
                  }}
                  onClick={() => setOrderType("Market")}
                >
                  Market
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: orderType === "Limit" ? "#1976d2" : "transparent",
                    color: orderType === "Limit" ? "#fff" : (theme === "dark" ? "#fff" : "#000"),
                    borderColor: orderType === "Limit" ? "#1976d2" : (theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)"),
                  }}
                  onClick={() => setOrderType("Limit")}
                >
                  Limit
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box mt={4}>
            <Box display="flex" justifyContent="space-between" fontSize="small" mt={2}>
              <Typography color={theme === "dark" ? "white" : "textSecondary"}>Available Amount:</Typography>
              <Typography>₹{availableAmount ? availableAmount.toFixed(2) : ""}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" fontSize="small" mt={2}>
              <Typography color={theme === "dark" ? "white" : "textSecondary"}>Required:</Typography>
              <Typography>
                ₹{
                  (quantity * lotSize * (orderType === "Market" ? selectedPrice?.price : limit)).toFixed(2)
                }
              </Typography>
            </Box>
          </Box>

          {/* Order Type Buttons */}
          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="contained"
              sx={{
                width: '45%',
                marginRight: 1,
                backgroundColor: 'green',
                color: 'white',
                opacity: isBuyInProgress ? 0.5 : 1, 
                pointerEvents: isBuyInProgress ? 'none' : 'auto',
                '&:hover': {
                  backgroundColor: 'darkgreen',
                },
              }}
              onClick={handleBuy}
              disabled={isBuyInProgress} 
            >
              Buy
            </Button>
            <Button
              variant="contained"
              sx={{
                width: '45%',
                backgroundColor: 'red',
                color: 'white',
                opacity: isSellInProgress ? 0.5 : 1,
                pointerEvents: isSellInProgress ? 'none' : 'auto',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              }}
              onClick={handleSell}
              disabled={isSellInProgress} 
            >
              Sell
            </Button>
          </Box>
        </CardContent>
      )}
    </Card>
  );
}

export default OptionsChain;
