import React, { useState } from "react";
import { FaGoogle, FaEye, FaEyeSlash } from 'react-icons/fa'; 
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'; 
import axios from 'axios';  
import './Login.css';
import config from '../../config';

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    forgotEmail: "", 
  });
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); 
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [message, setMessage] = useState(""); 
  const [validationError, setValidationError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const apiUrl = `${config.apiBaseUrl}/user/loginUser`;
      const response = await axios.post(apiUrl, {
        email: formData.email,
        password: formData.password,
      });

      setIsLoading(false);

      if (response.status === 200) {
        const data = response.data;
        const expiresIn = new Date();
        expiresIn.setTime(expiresIn.getTime() + 11 * 60 * 60 * 1000); 
        Cookies.set('userToken', data.userToken, { expires: expiresIn });
        Cookies.set('userId', data.userData.userId, { expires: expiresIn });

        navigate('/Dashboard');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: response.data.message || 'Invalid credentials. Please try again.',
        });
      }
    } catch (err) {
      setIsLoading(false);

      if (err.response) {
        if (err.response.status === 404) {
          Swal.fire({
            icon: 'error',
            title: 'No User Found',
            text: 'The email address you entered is not registered. Please check the email and try again.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.response.data.message || ` ${err.response.data}`,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'An Error Occurred',
          text: 'Network error. Please check your connection and try again.',
        });
      }
    }
  };

  const handleForgotPassword = async () => {
    setIsForgotPassword(true); 
    setValidationError("");
  };

  const handleGeneratePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setValidationError(""); 

    if (!formData.forgotEmail) {
      setValidationError("Please enter your email address.");
      setIsLoading(false);
      return; 
    }

    try {
      const apiUrl = `${config.apiBaseUrl}/user/forgetPassword`;
      const response = await axios.post(apiUrl, { email: formData.forgotEmail });

      if (response.status === 200) {
        setMessage("A new password has been sent to your email address.");
      } else if (response.status === 404) {
        setMessage(response.data || "User not found.");
      } else {
        setMessage(response.data?.message || "There was an issue sending the new password.");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 404) {
          setMessage(err.response.data || "User not found.");
        } else {
          setMessage(err.response.data?.message || "Error occurred while sending the new password.");
        }
      } else {
        setMessage("Error occurred while sending the new password.");
      }
    }

    setIsLoading(false);

    setTimeout(() => {
      setIsForgotPassword(false);
      setMessage("");
      setFormData({
        email: "",
        password: "",
        forgotEmail: "",
      });
    }, 5000);
  };

  return (
    <div className="login-container">
      <div className="form-section">
        <h1 className="heading">Join Smart Capital</h1>
        <h3 className="sub-heading">{isForgotPassword ? "Forgot Password" : "Sign In"}</h3>

        <div className="form-wrapper">
          {isForgotPassword ? (
            <form onSubmit={handleGeneratePassword}>
              <input
                type="email"
                name="forgotEmail"
                placeholder="Enter your email"
                value={formData.forgotEmail}
                onChange={handleChange}
                className="input-full"
              />
              {validationError && (
                <div className="validation-error">
                  <p>{validationError}</p>
                </div>
              )}
              <div className="submit-button-wrapper">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isLoading}
                  style={{ opacity: isLoading ? 0.2 : 1 }}
                >
                  {isLoading ? "Generating..." : "Generate Password"}
                </button>
              </div>
              {message && (
                <div className="message">
                  <p>{message}</p>
                </div>
              )}
            </form>
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="input-full"
              />

              <div className="password-input-wrapper">
                <input
                  type={passwordVisible ? "text" : "password"} 
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  className="input-full"
                />
                <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />} 
                </span>
              </div>

              <div className="submit-button-wrapper">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={isLoading}
                  style={{ opacity: isLoading ? 0.2 : 1 }} 
                >
                  {isLoading ? "Connecting..." : "Login"}
                </button>
              </div>

              <div className="forgot-password-wrapper">
                <a
                  href="javascript:void(0);"
                  className="forgot-password-link"
                  onClick={handleForgotPassword}
                >
                  Forgot Password?
                </a>
              </div>
            </form>
          )}
        </div>

        <div className="or-section">OR</div>

        <div className="account-footer">
          Don't have an account? <a href="/signup">Sign up</a>
        </div>
      </div>

      <div className="image-section-cover">
      </div>
    </div>
  );
};

export default Login;
