import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Typography, TextField, Button, Box, Grid } from "@mui/material";
import { ThemeContext } from "../../theme-context";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import config from "../../config";
import Swal from "sweetalert2";
import { useWebSocketData } from "../../Context/WebSocketContext";

const ShimmerLoader = ({ width, height }) => {
  return (
    <div 
      style={{
        width: width,
        height: height,
        background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
        backgroundSize: '200% 100%',
        animation: 'shimmer 1.5s infinite ease-in-out',
        borderRadius: '4px',
      }}
    />
  );
};

export default function StockBuyCard() {
  const { theme } = useContext(ThemeContext);
  const [quantity, setQuantity] = useState(0);
  const [orderType, setOrderType] = useState("Market");
  const [limit, setLimit] = useState("");
  const [availableAmount, setAvailableAmount] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({ name: "", exchange: "", tradingsymbol: "", lot_size: 0 });
  const { instrumentToken, exchange } = useParams();
  const { socketData } = useWebSocketData();
  const [userMargin, setUserMargin] = useState(null);

  const fetchCompanyDetails = async () => {
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
      const response = await axios.get(apiUrl, {
        headers: {
          "instrument-token": instrumentToken,
          "market-type": exchange,
        },
      });

      if (response.status === 200 && response.data) {
        setCompanyDetails({
          name: response.data.name || "NA",
          exchange: response.data.exchange || "NA",
          tradingsymbol: response.data.tradingsymbol || "NA",
          lastPrice: response.data.last_price || 0,
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Unexpected data format or empty response from the server.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Error fetching company details. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const fetchAvailableAmount = async () => {
    try {
      const userToken = Cookies.get("userToken");
      const userId = Cookies.get("userId");

      const apiUrl = `${config.apiBaseUrl}/user/getAvailableBalance`;
      const response = await axios.get(apiUrl, {
        headers: {
          "user-id": userId,
          "x-access-token": userToken,
        },
      });

      if (response.status === 200) {
        setAvailableAmount(response.data.availableBalance || 0);
        setUserMargin(response.data.userMargin || 0);
      } else {
        setAvailableAmount(null);
      }
    } catch (error) {
      setAvailableAmount(null);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
    fetchAvailableAmount();
  }, [instrumentToken, exchange]);

  const handleBuy = async () => {
    const requiredAmount = orderType === "Market" ? (quantity * companyDetails.lastPrice).toFixed(2) : (quantity * limit).toFixed(2);

    if (availableAmount < requiredAmount) {
      Swal.fire({
        title: 'Insufficient Balance',
        text: 'Please add funds to proceed with the purchase.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (quantity <= 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Quantity must be greater than 0 to place a buy order.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    try {
      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      const latestPrice = socketData[instrumentToken]?.last_price || companyDetails.lastPrice || limit;

      const orderData = {
        userId: userId,
        instrument_token: instrumentToken,
        symbol: companyDetails.tradingsymbol,
        orderType: orderType.toLowerCase(),
        quantity: quantity,
        price: limit || latestPrice,
        tradeType: 'equity',
        stockType: companyDetails.exchange,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/buy`, orderData, {
        headers: {
          'x-access-token': userToken,
        },
      });

      if (response.data && response.data.message) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        fetchAvailableAmount();
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'An unexpected error occurred while placing the buy order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.response?.data?.message || 'Failed to place buy order. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleSell = async () => {
    if (quantity <= 0) {
      Swal.fire({
        title: 'Error!',
        text: 'Quantity must be greater than 0 to place a sell order.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    try {
      const userId = Cookies.get('userId');
      const userToken = Cookies.get('userToken');
      const latestPrice = socketData[instrumentToken]?.last_price || companyDetails.lastPrice || limit;

      const orderData = {
        userId: userId,
        instrument_token: instrumentToken,
        symbol: companyDetails.tradingsymbol,
        orderType: orderType.toLowerCase(),
        quantity: quantity,
        price: limit || latestPrice,
        tradeType: 'equity',
        stockType: companyDetails.exchange,
      };

      const response = await axios.post(`${config.apiBaseUrl}/order/sell`, orderData, {
        headers: {
          'x-access-token': userToken,
        },
      });

      if (response.data && response.data.message) {
        Swal.fire({
          title: 'Success!',
          text: response.data.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        fetchAvailableAmount();
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'An unexpected error occurred while placing the sell order.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 428) {
          Swal.fire({
            title: 'Error!',
            text: error.response.data.message || 'Insufficient Stock Quantity Available',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: error.response?.data?.message || 'Failed to place sell order. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Network error or request timeout. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  if (!companyDetails.lastPrice || !availableAmount) {
    return (
      <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5" }}>
        <ShimmerLoader width="100%" height="100%" />
      </div>
    );
  }

  const latestPrice = socketData[instrumentToken]?.last_price || companyDetails.lastPrice || 0;
  const requiredAmount = orderType === "Market" ? (quantity * latestPrice * userMargin).toFixed(2) : (quantity * limit *userMargin).toFixed(2);

  return (
    <div>
      <Card sx={{ p: 2,  display: "flex", flexDirection: "column", backgroundColor: theme === "dark" ? "#181818" : "#edebeb", color: theme === "dark" ? "#fff" : "#000" }}>
        <CardContent style={{ flexGrow: 1, overflowY: "auto" }}>
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"} mb={1}>
              Quantity
            </Typography>

            <Box display="flex" alignItems="center">
              <TextField
                type="text"  
                value={quantity === "" ? "" : quantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || /^[0-9]*$/.test(value)) {
                    setQuantity(value);
                  }
                }}
                variant="outlined"
                fullWidth
                style={{
                  borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                  backgroundColor: theme === "dark" ? "#333" : "transparent",
                  height: "40px",
                }}
                InputProps={{
                  style: {
                    color: theme === "dark" ? "white" : "black",
                    height: "100%",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: theme === "dark" ? "white" : "black",
                  },
                }}
              />
              <Button
                variant="outlined"
                style={{
                  marginLeft: "8px",
                  color: theme === "dark" ? "white" : "black",
                  borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                  height: "40px", 
                  display: "flex", 
                  justifyContent: "center", 
                  width: "56px", 
                }}
                onClick={() => setQuantity(Math.max(1, Number(quantity) + 1))}
              >
                +
              </Button>
            </Box>
          </Box>

          <Box mt={4}>
            <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"}>Price</Typography>
            <TextField
              value={orderType === "Limit" ? (limit || companyDetails.lastPrice || "Fetching...") : latestPrice} 
              variant="outlined"
              fullWidth
              onChange={(e) => setLimit(e.target.value)}  
              disabled={orderType === "Market"}  
              style={{
                borderColor: theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)",
                backgroundColor: theme === "dark" ? "#333" : "transparent", height: "40px",
              }}
              InputProps={{
                style: {
                  color: theme === "dark" ? "white" : "black",
                  height: "40px",
                },
              }}
              InputLabelProps={{
                style: {
                  color: theme === "dark" ? "white" : "black",
                  height: "100%"
                },
              }}
            />
          </Box>
          
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: orderType === "Market" ? "#1976d2" : "transparent",
                    color: orderType === "Market" ? "#fff" : (theme === "dark" ? "#fff" : "#000"),
                    borderColor: orderType === "Market" ? "#1976d2" : (theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)"),
                  }}
                  onClick={() => setOrderType("Market")}
                >
                  Market
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: orderType === "Limit" ? "#1976d2" : "transparent",
                    color: orderType === "Limit" ? "#fff" : (theme === "dark" ? "#fff" : "#000"),
                    borderColor: orderType === "Limit" ? "#1976d2" : (theme === "dark" ? "white" : "rgba(0, 0, 0, 0.23)"),
                  }}
                  onClick={() => setOrderType("Limit")}
                >
                  Limit
                </Button>
              </Grid>
            </Grid>
          </Box>
          
          <Typography variant="body2" color={theme === "dark" ? "white" : "textSecondary"} mt={4}>
            Available Amount : ₹{(availableAmount ? availableAmount.toFixed(2) : "")}
          </Typography>

          <Typography variant="body2"color={theme === "dark" ? "white" : "textSecondary"} mt={2}>
            Required Total : ₹{requiredAmount}
          </Typography>

          <Grid container spacing={2} mt={4} style={{paddingBottom: "6px", paddingLeft: "16px", paddingRight: "16px" }}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={handleBuy}
                style={{
                  backgroundColor: theme === "dark" ? "#81c784" : "#4caf50",
                  color: "white",
                }}
              >
                Buy
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={handleSell}
                style={{ backgroundColor: theme === "dark" ? "#e57373" : "#f44336", color: "white" }}
              >
                Sell
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
