import React, { useContext, useEffect, useState } from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, Button, Select, MenuItem, InputLabel, FormControl, TablePagination, TextField } from "@mui/material";
import { ThemeContext } from "../../theme-context";
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import Cookies from 'js-cookie';
import { useMediaQuery } from "@mui/material";
import StockChartIcon from "../../Assests/Line_up.png";
import WatchListIcon from "../../Assests/Watchlist.png";
import { CheckCircle } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useWebSocketData } from "../../Context/WebSocketContext"; 

const StockTable = () => {
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [stockData, setStockData] = useState([]);
  const [error, setError] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedExchange, setSelectedExchange] = useState("");  
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const { socketData } = useWebSocketData(); 
  const isMobile = useMediaQuery('(max-width:600px)');

  const colors = {
    primary: {
      400: `var(--primary-${theme}-400)`,
      500: `var(--primary-${theme}-500)`,
    },
    greenAccent: {
      500: `var(--greenAccent-${theme}-500)`,
      600: `var(--greenAccent-${theme}-600)`,
    },
    grey: {
      100: `var(--grey-${theme}-100)`,
      300: `var(--grey-${theme}-300)`,
      900: `var(--grey-${theme}-900)`,
    },
    white: theme === 'dark' ? '#ffffff' : '#000000', 
  };

  const fetchData = async () => {
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');

      const headers = {
        'Content-Type': 'application/json', 
        'x-access-token': token, 
        'user-id': userId, 
      };

      const apiUrl = `${config.apiBaseUrl}/stock/getStockList`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: headers,  
      });
      const data = await response.json();
      
      return data.map(item => ({
        id: item.instrument_token, 
        symbol: item.tradingsymbol,  
        exchange: item.exchange,     
        name: item.name,
        last_price: item.last_price,
        net_change: item.net_change,
        ohlc: item.ohlc,
        isWatchlist: item.isWatchlist,
        segment : item.segment, 
        logo : item.logo,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAndSetData = async () => {
      const fetchedData = await fetchData();
      if (fetchedData.length > 0) {
        setStockData(fetchedData);
      } else {
        setError("Failed to fetch data");
      }
      setLoading(false);
    };

    fetchAndSetData();
  }, []);

  useEffect(() => {
    if (socketData && stockData.length > 0) {
      const updatedData = stockData.map(stock => {
        const liveData = socketData[stock.id];  
        if (liveData) {
          return {
            ...stock,
            last_price: liveData.last_price,
            net_change: liveData.net_change,
            ohlc: liveData.ohlc,
          };
        }
        return stock;
      });
      setStockData(updatedData);
    }
  }, [socketData]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleExchangeChange = (event) => {
    setSelectedExchange(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const renderShimmerRows = () => (
    [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
        <TableCell><Skeleton variant="text" width="80%" height={40} /></TableCell>
      </TableRow>
    ))
  );

  const handleAddToWatchlist = (instrumentToken) => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (!userId || !accessToken) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Missing authentication details',
      });
      return;
    }

    const token = parseInt(instrumentToken, 10);
    if (isNaN(token)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Token',
        text: 'The instrument token is invalid.',
      });
      return;
    }

    const data = {
      instrument_tokens: [token],
    };

    const apiUrl = `${config.apiBaseUrl}/user/addToWatchList`;

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-id': userId,
        'x-access-token': accessToken,
      },
      body: JSON.stringify(data),
    })
      .then(() => {
        fetchData().then(fetchedData => {
          setStockData(fetchedData); 
        });

        Swal.fire({
          icon: 'success',
          title: 'Stock Added',
          text: 'The stock has been added to your watchlist.',
        });
      })
      .catch((error) => {
        console.error('Error Adding stock:', error);

        Swal.fire({
          icon: 'error',
          title: 'Failed to Add Stock',
          text: 'There was an error adding the stock to your watchlist.',
        });
      });
  };

  return (
    <Box m="20px">
        <Box display="flex" justifyContent="space-between" mb={2} sx={{ backgroundColor: colors.primary[400], padding: '10px', borderRadius: '8px', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: 'auto' }}>
              <InputLabel sx={{ color: theme === 'light' ? colors.grey[900] : 'white' }}>Rows per page</InputLabel>
              <Select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                label="Rows per page"
                sx={{
                  color: theme === 'light' ? colors.grey[900] : 'white',
                  backgroundColor: theme === 'light' ? 'white' : colors.grey[900],
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme === 'light' ? colors.grey[300] : colors.grey[900],
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme === 'light' ? colors.primary[500] : colors.greenAccent[500],
                  },
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: 'auto' }}>
              <InputLabel sx={{ color: theme === 'light' ? colors.grey[900] : 'white' }}>Exchange</InputLabel>
              <Select
                value={selectedExchange}
                onChange={handleExchangeChange}
                label="Exchange"
                sx={{
                  color: theme === 'light' ? colors.grey[900] : 'white',
                  backgroundColor: theme === 'light' ? 'white' : colors.grey[900],
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme === 'light' ? colors.grey[300] : colors.grey[900],
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme === 'light' ? colors.primary[500] : colors.greenAccent[500],
                  },
                }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="NSE">NSE</MenuItem>
                <MenuItem value="BSE">BSE</MenuItem>
              </Select>
            </FormControl>

            <TextField
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              label="Search"
              variant="outlined"
              size="small"
              sx={{
                width: 'auto',
                color: theme === 'light' ? colors.grey[900] : 'white',
                backgroundColor: theme === 'light' ? 'white' : colors.grey[900],
                '& .MuiInputBase-input': {
                  color: theme === 'light' ? colors.grey[900] : 'white',
                },
                '& .MuiInputLabel-root': {
                  color: theme === 'light' ? colors.grey[900] : 'white',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme === 'light' ? colors.grey[300] : 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme === 'light' ? colors.primary[500] : 'white',
                },
              }}
            />
          </Box>
        </Box>

        {/* Watchlist Table */}
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="auto" gap="20px">
          <Box gridColumn="span 12">
            <Box sx={{ width: "100%" }}>
              <Box gridColumn="span 12" display="flex" flexDirection="column" gap={2} sx={{ paddingTop: '20px' }}>
                <Box backgroundColor={colors.primary[400]} p={2} borderRadius={2}>
                  <TableContainer component={Paper} elevation={0} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', marginTop: '15px', marginBottom: '15px', minHeight: 'auto', height: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", position: 'relative',  borderBottom: 'none', lineHeight: '1px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <strong>Company Name</strong>
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", position: 'relative', display: isMobile ? "none" : "table-cell", borderBottom: 'none', lineHeight: '1px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <strong>Exchange</strong>
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", position: 'relative', borderBottom: 'none', lineHeight: '1px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <strong>Current Price</strong>
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", position: 'relative', borderBottom: 'none', lineHeight: '1px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <strong>Net Change</strong>
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", position: 'relative', borderBottom: 'none', lineHeight: '1px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? renderShimmerRows() : stockData
                          .filter(stock => !selectedExchange || stock.exchange === selectedExchange) 
                          .filter(stock =>
                            stock.symbol.toLowerCase().includes(searchQuery.toLowerCase()) || 
                            stock.name.toLowerCase().includes(searchQuery.toLowerCase())
                          ) 
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) 
                          .map((stock, index) => {
                            const netChangeColor = (stock.net_change === 0 || !stock.net_change)
                              ? (theme === 'dark' ? 'white' : colors.grey[900])
                              : (stock.net_change > 0 ? colors.greenAccent[500] : stock.net_change < 0 ? "#f44336" : colors.grey[900]);

                            return (
                              <TableRow key={index} sx={{ '& td, & th': { borderBottom: 'none' } }}>
                                <TableCell
                                  sx={{
                                    color: theme === "light" ? colors.grey[900] : "white",
                                    position: "relative",
                                    lineHeight: "1",
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "column", 
                                    alignItems: "flex-start",
                                    gap: "5px", 
                                  }}
                                  onClick={() => {
                                    const segment = stock.segment;
                                    if (segment === "INDICES") {
                                      navigate(`/StockChartFno/${stock.id}/${stock.exchange}`);
                                    } else if (segment === "NSE" || segment === "BSE") {
                                      navigate(`/StockChart/${stock.id}/${stock.exchange}`);
                                    }
                                  }}
                                >
                                  {stock.logo ? (
                                    <img
                                      src={stock.logo}
                                      alt={stock.symbol}
                                      style={{ width: "50px", height: "30px", objectFit: "contain" }}
                                    />
                                  ) : null}
                                  <span>{stock.name}</span>
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: "5%",
                                      width: "90%",
                                      height: "1px",
                                      backgroundColor: theme === "light" ? "grey" : "white",
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ color: theme === "light" ? colors.grey[900] : "white", position: 'relative', lineHeight: '1', display: isMobile ? "none" : "table-cell"  }}>
                                  {stock.exchange}
                                  <Box sx={{ position: 'absolute', top: 0, right: '5%', width: '90%', height: '1px', backgroundColor: theme === "light" ? "grey" : "white" }} />
                                </TableCell>
                                <TableCell sx={{ color: netChangeColor, position: 'relative', lineHeight: '1' }}>
                                  {stock.last_price}
                                  <Box sx={{ position: 'absolute', top: 0, right: '5%', width: '90%', height: '1px', backgroundColor: theme === "light" ? "grey" : "white" }} />
                                </TableCell>
                                <TableCell sx={{ color: netChangeColor, position: 'relative', lineHeight: '1' }}>
                                  {stock.net_change.toFixed(2)}
                                  <Box sx={{ position: 'absolute', top: 0, right: '5%', width: '90%', height: '1px', backgroundColor: theme === "light" ? "grey" : "white" }} />
                                </TableCell>
                                <TableCell>
                                  <Box display="flex" gap={1}>
                                    <Button
                                      onClick={() => handleAddToWatchlist(stock.id)}
                                      sx={{
                                        border: "none",
                                        padding: 0,
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img src={WatchListIcon} alt="Add to Watchlist" width={20} height={20} />
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        const segment = stock.segment;
                                        if (segment === "INDICES") {
                                          navigate(`/StockChartFno/${stock.id}/${stock.exchange}`);
                                        } else if (segment === "NSE" || segment === "BSE") {
                                          navigate(`/StockChart/${stock.id}/${stock.exchange}`);
                                        }
                                      }}
                                      sx={{
                                        background: 'transparent',
                                        border: 'none',
                                      }}
                                    >
                                      <img src={StockChartIcon} alt="View Stock Chart" width={20} height={20} />
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[15, 25]}
                    component="div"
                    count={stockData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    sx={{
                      backgroundColor: 'transparent',
                      '& .MuiTablePagination-select': { color: theme === 'light' ? colors.grey[900] : 'white'},
                      '& .MuiTablePagination-selectIcon': {color: theme === 'light' ? colors.grey[900] : 'white'},
                      '& .MuiTablePagination-actions .MuiIconButton-root': {color: theme === 'light' ? colors.grey[900] : 'white',  },
                      '& .MuiTablePagination-input': {color: theme === 'light' ? colors.grey[900] : 'white',  },
                      '& .MuiTypography-root': { color: theme === 'light' ? colors.grey[900] : 'white',  },
                      '& .MuiTablePagination-toolbar': {color: theme === 'light' ? colors.grey[900] : 'white', },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default StockTable;
