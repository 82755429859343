import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Typography, Grid, Select, MenuItem, FormControl, InputLabel, FormHelperText, Card, CardContent,Box } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import Cookies from 'js-cookie'; 
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import IllustrationImage from '../../Assests/IllustrationImage.png';
import NoBankImage from '../../Assests/addbanking.png'; 
import config from '../../config'; 

const BankDetails = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [reEnterAccountNumber, setReEnterAccountNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountType, setAccountType] = useState('Saving');
  const [accountHolder, setAccountHolder] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [error, setError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [bankCards, setBankCards] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { theme } = useContext(ThemeContext);

  const getThemeColors = () => {
    return {
      text: theme === 'dark' ? '#fff' : '#111',
      background: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
      borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      uploadBg: theme === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.01)',
    };
  };

  const colors = getThemeColors();

  const fetchBankDetails = async () => {
    const userId = Cookies.get('userId');
    const token = Cookies.get('userToken');

    if (!userId || !token) {
      setError('Authentication required');
      return;
    }

    try {
      const response = await fetch(`${config.apiBaseUrl}/user/getBankCards`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
          'user-id': userId,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setBankCards(data.bankCards);
      } else {
        setError(data.message || 'Failed to fetch bank details');
      }
    } catch (error) {
      setError('Failed to fetch bank details. Please try again.');
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const handleAccountNumberChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    setAccountNumber(newValue);
  };

  const handleSubmit = async () => {
    setFormSubmitted(true);
    setError('');
    setIsSubmitting(true);

    if (!accountNumber || !reEnterAccountNumber || !bankName || !accountType || !accountHolder || !ifscCode) {
      setError('All fields are required.');
      setIsSubmitting(false);
      return;
    }

    if (accountNumber !== reEnterAccountNumber) {
      setError('Account numbers do not match.');
      setIsSubmitting(false);
      return;
    }

    const userId = Cookies.get('userId');

    if (!userId) {
      setError('User ID is missing in cookies.');
      setIsSubmitting(false);
      return;
    }

    const accountData = {
      userId: userId,
      acc_holder: accountHolder,
      acc_number: accountNumber,
      bank_name: bankName,
      ifsc_Code: ifscCode,
      acc_Type: accountType,
    };

    const token = Cookies.get('userToken');

    if (!token) {
      setError('Authentication token is missing.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('https://backend.smartcapital.co.in/user/addBankCard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify(accountData),
      });

      const data = await response.json();

      setIsSubmitting(false);

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'Bank details added successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        });

        fetchBankDetails();  
        
        setError('');
        setShowForm(false);
      } else if (response.status === 402) {
        Swal.fire({
          title: 'KYC Pending',
          text: 'Your KYC is still pending. Please complete your KYC process before adding bank details.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: data.message || 'Something went wrong. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to submit data. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };


  return (
    <div style={{ padding: '0 16px 32px', minHeight: '300px' }}>
      {!showForm && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" style={{ color: colors.text }}>
            Bank Details
          </Typography>

          {bankCards.length < 2 && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              style={{ marginTop: '0px' }}
              onClick={() => setShowForm(true)}
            >
              Add Bank Details
            </Button>
          )}
        </div>
      )}

      {/* {error && (
        <Typography variant="body2" color="error" style={{ marginBottom: '16px' }}>
          {error}
        </Typography>
      )} */}

      {bankCards.length === 0 && !showForm && (
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <img 
            src={NoBankImage} 
            alt="No Bank Records" 
            style={{ maxWidth: '100%', height: '200px' }} 
          />
          <Typography variant="body1" style={{ marginTop: '16px', color: colors.text }}>
            No Bank Records Found
          </Typography>
        </div>
      )}

      {/* Bank Cards Listing */}
      {!showForm && bankCards.length > 0 && (
        <Grid container spacing={2} justifyContent="center">
          {bankCards.map((card) => {
            if (!card) return null;

            return (
              <Grid item xs={12} sm={6} md={4} key={card._id}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Card
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      backgroundColor: colors.background,
                      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                      padding: '16px',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: 'grey',
                          fontWeight: 'bold',
                          fontSize: '24px',
                          flex: 1,
                        }}
                      >
                        {card.bank_name || 'Unknown Bank'}
                      </Typography>

                      {card.image && (
                        <img src={card.image} alt="Bank Logo" style={{ width: '70px', height: 'auto' }} />
                      )}
                    </div>

                    <CardContent
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0',
                        flex: 1,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Grid container direction="column" spacing={1}>
                            <Grid item>
                              <Grid container justifyContent="space-between">
                                <Typography style={{ color: colors.text }}>Name:</Typography>
                                <Typography style={{ color: colors.text, fontWeight: 'bold' }}>
                                  {card.acc_holder}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item>
                              <Grid container justifyContent="space-between" style={{ flexWrap: 'nowrap' }}>
                                <Typography style={{ color: colors.text }}>Number:</Typography>
                                <Typography
                                  style={{
                                    color: colors.text,
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap',  
                                    overflow: 'hidden',    
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {card.acc_number}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item>
                              <Grid container justifyContent="space-between">
                                <Typography style={{ color: colors.text }}>IFSC Code:</Typography>
                                <Typography style={{ color: colors.text, fontWeight: 'bold' }}>
                                  {card.ifsc_Code}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item>
                              <Grid container justifyContent="space-between">
                                <Typography style={{ color: colors.text }}>Type:</Typography>
                                <Typography style={{ color: colors.text, fontWeight: 'bold' }}>
                                  {card.acc_Type}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                          <img
                            src={IllustrationImage}
                            alt="Illustration"
                            style={{
                              width: '100%',
                              height: 'auto',
                              objectFit: 'contain',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}


      {/* Form for Adding Bank Card */}
      {showForm && (
        <div>
          <Typography variant="h6" style={{ marginBottom: '24px', color: colors.text }}>
            Add Bank
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Account Number"
                value={accountNumber}
                onChange={handleAccountNumberChange}
                variant="outlined"
                placeholder="Enter account number"
                InputProps={{
                  style: {
                    backgroundColor: colors.background,
                    color: colors.text,
                  },
                }}
                InputLabelProps={{
                  style: { color: colors.text },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Re-enter Account Number"
                value={reEnterAccountNumber}
                onChange={(e) => setReEnterAccountNumber(e.target.value)}
                variant="outlined"
                placeholder="Re-enter Account Number"
                InputProps={{
                  style: {
                    backgroundColor: colors.background,
                    color: colors.text,
                  },
                }}
                InputLabelProps={{
                  style: { color: colors.text },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Bank Name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                variant="outlined"
                placeholder="Enter bank name"
                InputProps={{
                  style: {
                    backgroundColor: colors.background,
                    color: colors.text,
                  },
                }}
                InputLabelProps={{
                  style: { color: colors.text },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" style={{ backgroundColor: colors.background }}>
                <InputLabel style={{ color: colors.text }}>Account Type</InputLabel>
                <Select
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                  label="Account Type"
                  style={{ color: colors.text }}
                >
                  <MenuItem value="Saving">Saving</MenuItem>
                  <MenuItem value="Current">Current</MenuItem>
                </Select>
                {formSubmitted && !accountType && (
                  <FormHelperText style={{ color: 'red' }}>This field is required.</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Account Holder"
                value={accountHolder}
                onChange={(e) => setAccountHolder(e.target.value)}
                variant="outlined"
                placeholder="Enter account holder name"
                InputProps={{
                  style: {
                    backgroundColor: colors.background,
                    color: colors.text,
                  },
                }}
                InputLabelProps={{
                  style: { color: colors.text },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="IFSC Code"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                variant="outlined"
                placeholder="Enter IFSC code"
                InputProps={{
                  style: {
                    backgroundColor: colors.background,
                    color: colors.text,
                  },
                }}
                InputLabelProps={{
                  style: { color: colors.text },
                }}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: '24px' }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#1a73e8',
                color: 'white',
                textTransform: 'none',
                minWidth: '120px',
                opacity: isSubmitting ? 0.6 : 1, 
                pointerEvents: isSubmitting ? 'none' : 'auto', 
              }}
              onClick={handleSubmit}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'} 
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankDetails;
