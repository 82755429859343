import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Typography, Grid } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import axios from 'axios';
import Cookies from 'js-cookie'; 
import config from '../../config';
import Swal from 'sweetalert2';

const  PersonaDetails= () => {
  const { theme } = useContext(ThemeContext);

  const [generalInfo, setGeneralInfo] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    contact: '',
  });
  const [isKyc, setIsKyc] = useState(false);
  const [loading, setLoading] = useState(false); 

  const getThemeColors = () => {
    return {
      text: theme === 'dark' ? '#fff' : '#111',
      background: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
      borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      uploadBg: theme === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.01)',
    };
  };

  const colors = getThemeColors();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = () => {
    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (userId && accessToken) {
      const apiUrl = `${config.apiBaseUrl}/user/getProfile`;
      axios
        .get(apiUrl, {
          headers: {
            'user-id': userId,
            'x-access-token': accessToken,
          },
        })
        .then((response) => {
          const { first_name, last_name, user_name, email, contact, isKYC } = response.data.userData;

          setGeneralInfo({
            firstName: first_name,
            lastName: last_name,
            userName: user_name,
            email,
            contact: contact || '',
          });

          setIsKyc(isKYC); 
        })
        .catch((error) => {
          console.error('Error fetching profile data:', error);
        });
    }
  };

  const handleSaveChanges = () => {
    if (!generalInfo.firstName || !generalInfo.lastName || !generalInfo.contact) {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill in all required fields (First Name, Last Name, and Contact Number).',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return; 
    }

    const userId = Cookies.get('userId');
    const accessToken = Cookies.get('userToken');

    if (userId && accessToken) {
      const payload = {
        first_name: generalInfo.firstName,
        last_name: generalInfo.lastName,
        contact: generalInfo.contact,
      };

      setLoading(true);

      const apiUrl = `${config.apiBaseUrl}/user/updateProfile`;
      axios
        .post(apiUrl, payload, {
          headers: {
            'user-id': userId,
            'x-access-token': accessToken,
          },
        })
        .then((response) => {
          Swal.fire({
            title: 'Success!',
            text: 'Your profile has been updated successfully.',
            icon: 'success',
            confirmButtonText: 'OK',
          });

          fetchUserProfile();
        })
        .catch((error) => {
          console.error('Error saving profile:', error);
          Swal.fire({
            title: 'Error!',
            text: 'There was an error updating your profile. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        })
        .finally(() => {
          setLoading(false); 
        });
    }
  };

  return (
    <div style={{ padding: '0 16px 32px' }}>
      <Typography variant="h6" style={{ marginBottom: '24px', color: colors.text }}>
        Personal Information
      </Typography>

      <Grid container spacing={2} style={{ maxWidth: '100%' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="First Name"
            value={generalInfo.firstName}
            variant="outlined"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
            disabled={isKyc} 
            onChange={(e) => setGeneralInfo({ ...generalInfo, firstName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Last Name"
            value={generalInfo.lastName}
            variant="outlined"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
            disabled={isKyc} 
            onChange={(e) => setGeneralInfo({ ...generalInfo, lastName: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Username"
            value={generalInfo.userName}
            variant="outlined"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
            disabled 
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            value={generalInfo.email}
            variant="outlined"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
            disabled 
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone Number"
            value={generalInfo.contact}
            variant="outlined"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
            disabled={isKyc} 
            onChange={(e) => setGeneralInfo({ ...generalInfo, contact: e.target.value })}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: '24px', display: 'flex', gap: '12px' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#1a73e8',
            color: 'white',
            textTransform: 'none',
            opacity: loading ? 0.5 : 1, 
            pointerEvents: loading ? 'none' : 'auto', 
          }}
          onClick={handleSaveChanges} 
        >
          {loading ? 'Updating...' : 'Save Changes'}  
        </Button>
      </div>
    </div>
  );
}

export default PersonaDetails;
