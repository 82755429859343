import React, { useContext } from 'react';
import { ThemeContext } from '../../theme-context';
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa'; 
import './Footer.css';
import logo from '../../Assests/logosc.png';

const Footer = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <footer className={`footer-container ${theme}`}>
      <div className="footer-content">
        <div className="footer-branding">
          <img
            src={logo} 
            className="footer-logo"
            alt="Smart Capital Logo"
          />
        </div>

        <div className="footer-center">
          <div className="social-icons">
            <a href="#"><FaFacebook /></a>
            <a href="https://www.instagram.com/invites/contact/?utm_source=ig_contact_invite&utm_medium=copy_link&utm_content=wxuipa3 "><FaInstagram /></a>
            <a href="#"><FaLinkedin /></a>
          </div>
          <div className="footer-links">
            <ul>
              <li><a href="/profile">Profile</a></li>
              <li><a href="/wallet">Wallet</a></li>
              <li><a href="/StockTable">Stock</a></li>
              <li><a href="/Watchlist">Watchlist</a></li>
            </ul>
          </div>
        </div>

        <div className="contact-us">
          <button className="contact-us-button">Contact Us</button>
        </div>
      </div>

      <div className="footer-contact">
        <div className="contact-item">
          <FaMapMarkerAlt /> <p>Office no. 2265 professor colony, Aadartal Suhagi , Jabalpur,482001</p>
        </div>
        <div className="contact-item">
          <FaEnvelope /> <a href="mailto:contact@smartcapital.com">Info@smartcapital.co.in</a>
        </div>
        <div className="contact-item">
          <FaPhone /> <a href="tel:8516992847">8516992847</a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>© 2024 Smart Capital All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
