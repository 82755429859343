import React, { useState, useContext } from 'react';
import { TextField, Button, Typography, Grid } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import config from '../../config';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); 
  const { theme } = useContext(ThemeContext);

  const getThemeColors = () => {
    return {
      text: theme === 'dark' ? '#fff' : '#111',
      background: theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)',
      borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
      uploadBg: theme === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.01)',
    };
  };

  const colors = getThemeColors();

  const handleChangePassword = async () => {
    const token = Cookies.get('userToken');
    if (!token) {
      Swal.fire({
        title: 'Error!',
        text: 'No valid session found. Please login again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    const data = {
      email,
      password,
      newPassword,
    };

    setLoading(true); 

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/user/changePassword`,
        data,
        {
          headers: {
            'x-access-token': token,
          },
        }
      );

      Swal.fire({
        title: 'Success!',
        text: 'Your password has been changed successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      });

      setPassword('');
      setNewPassword('');
      setEmail('');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Swal.fire({
          title: 'Error!',
          text: 'Password does not match.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'There was an issue changing the password. Please check your password.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div style={{ padding: '0 16px 32px' }}>
      <Typography variant="h6" style={{ marginBottom: '24px', color: colors.text }}>
        Change password
      </Typography>

      <Grid container spacing={3} style={{ maxWidth: '100%' }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            placeholder="Enter your email address"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Current Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            placeholder="Enter your current password"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            variant="outlined"
            placeholder="Enter your new password"
            InputProps={{
              style: {
                backgroundColor: colors.background,
                color: colors.text,
              },
            }}
            InputLabelProps={{
              style: { color: colors.text },
            }}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: '24px' }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#1a73e8',
            color: 'white',
            textTransform: 'none',
            minWidth: '120px',
            opacity: loading ? 0.5 : 1, 
            pointerEvents: loading ? 'none' : 'auto', 
          }}
          onClick={handleChangePassword}
        >
          {loading ? 'Updating...' : 'Save Changes'} 
        </Button>
      </div>
    </div>
  );
};

export default ChangePassword;
