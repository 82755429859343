import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Box, Button, List, Typography, Paper, IconButton, CircularProgress } from '@mui/material';
import { ThemeContext } from '../../theme-context';
import { useWebSocketData } from '../../Context/WebSocketContext';
import config from '../../config';
import NoDataIllustrator from '../../Assests/F&O.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Swal from 'sweetalert2';
import { ArrowUpRight } from 'lucide-react';


export default function PortfolioFno() {
  const [holdings, setHoldings] = useState([]);
  const { theme } = useContext(ThemeContext);
  const { socketData } = useWebSocketData();
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [companyDetails, setCompanyDetails] = useState({ name: "", exchange: "", tradingsymbol: "", lot_size: 0 });
  const isDark = theme === 'dark';
  const [loading, setLoading] = useState(true); 

  const fetchPortfolio = async () => {
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');

      const response = await axios.get(`${config.apiBaseUrl}/order/portfolio`, {
        headers: {
          'x-access-token': token,
          'user-id': userId,
          'trade-type': 'option',
        },
      });

      if (response.data.portfolio) {
        setHoldings(response.data.portfolio);
        setTotalInvestedAmount(response.data.totalInvestedAmount);
      }
    } catch (error) {
      console.error('Error fetching portfolio:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanyDetails = async (instrument_token, stockType) => {
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
      const response = await axios.get(apiUrl, {
        headers: {
          "instrument-token": instrument_token,
          "market-type": stockType,
        },
      });

      if (response.status === 200 && response.data) {
        setCompanyDetails({
          lastPrice: response.data.last_price || 0,
        });
      }
    } catch (error) {
      console.error("Error fetching company details. Please try again.", error);
    }
  };

  useEffect(() => {
    fetchPortfolio();
  }, []);

  useEffect(() => {
    if (holdings.length > 0) {
      holdings.forEach(holding => {
        fetchCompanyDetails(holding.instrument_token, holding.stockType);
      });
    }
  }, [holdings]);

  const getLastPrice = (instrument_token) => {
    const socketPrice = socketData[instrument_token]?.last_price;
    if (socketPrice) {
      return socketPrice;
    } else if (companyDetails && companyDetails.lastPrice) {
      return companyDetails.lastPrice;
    }
    return 0;
  };

  const activeHoldings = holdings.filter(holding => holding.isActive === true);

  const sellStock = async (instrument_token, symbol, quantity, price, stockType) => {
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');
      
      const response = await axios.post(
        `${config.apiBaseUrl}/order/sell`,
        {
          instrument_token,
          symbol,
          quantity,
          price,
          tradeType: 'equity',
          stockType,
          userId: userId,
          orderType: "market",
        },
        {
          headers: {
            'x-access-token': token,
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: 'Stock sold successfully.',
          icon: 'success',
          confirmButtonText: 'Okay',
        });

        fetchPortfolio();
      } else {
        Swal.fire({
          title: 'Error',
          text: response.data.message || 'Something went wrong!',
          icon: 'error',
          confirmButtonText: 'Okay',
        });
      }
    } catch (error) {
      console.error("Error selling stock:", error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to sell stock. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  };

  const formattedPrice = (price) => price.toFixed(2);

  const calculateReturn = (quantity, lastPrice, investedAmount) => {
    const totalReturn = (quantity * lastPrice) - investedAmount;
    const formattedReturn = totalReturn.toFixed(2); // To show up to 2 decimal places

    return { totalReturn: formattedReturn, isPositive: totalReturn >= 0 };
  };

  return (
    <Box sx={{ maxWidth: '100%', margin: '0 auto', p: 2, minHeight: '300px', backgroundColor: isDark ? '#121212' : '#fff' }}>
      {loading ? ( 
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '90vh' }}>
          <CircularProgress />
        </Box>
      ) : activeHoldings.length === 0 ? ( 
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <img src={NoDataIllustrator} alt="No Data" style={{ width: '400px', height: 'auto' }} />
        </Box>
      ) : ( 
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1.5, fontSize: '0.875rem', fontWeight: 400, color: isDark ? '#fff' : '#333' }}>
              F&O Positions ({activeHoldings.length})
            </Typography>
            <Button
              endIcon={<ArrowUpRight size={16} />}
              sx={{
                color: 'rgb(0, 102, 255)',
                textTransform: 'none',
                fontSize: '0.875rem',
                fontWeight: 500,
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: 0.8,
                },
              }}
            >
              EXIT ALL
            </Button>
          </Box>

          {/* <Typography sx={{ fontSize: '0.875rem', color: 'rgb(102, 102, 102)', mb: 2 }}>
            Total returns
          </Typography> */}

          <List sx={{ p: 0 }}>
            {activeHoldings.map((position, index) => {
              const lastPrice = getLastPrice(position.instrument_token);
              const { totalReturn, isPositive } = calculateReturn(position.quantity, lastPrice, position.investedAmount);

              return (
                <Paper
                  key={index}
                  elevation={0}
                  sx={{
                    border: '1px solid rgba(0, 0, 0, 0.06)',
                    borderRadius: 1.5,
                    mb: 1.5,
                    p: 2,
                    backgroundColor: isDark ? '#333' : '#fff',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
                    <Typography sx={{ color: position.isActive ? (isDark ? '#76c7f3' : 'rgb(0, 102, 255)') : 'red', fontSize: '1rem', fontWeight: 500 }}>
                      {position.symbol}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        sellStock(
                          position.instrument_token,
                          position.symbol,
                          position.quantity,
                          lastPrice, 
                          position.stockType
                        )
                      }
                      color="secondary"
                      sx={{ ml: 1 }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 1, '& .MuiTypography-root': { fontSize: '0.875rem', lineHeight: 1.5, color: isDark ? '#ccc' : 'rgb(102, 102, 102)' } }}>
                    <Box>
                      <Typography>Invested ₹{formattedPrice(position.investedAmount)}</Typography>
                      <Typography>Qty. {position.quantity}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>Total Return {isPositive ? `+₹${totalReturn}` : `-₹${totalReturn}`}</Typography>
                      <Typography>Avg. ₹{formattedPrice(position.avgPrice)}</Typography>
                      <Typography>₹{formattedPrice(lastPrice)} (Mkt. Price)</Typography>
                      {/* <Typography sx={{ color: isPositive ? 'green' : 'red', fontWeight: 500 }}>
                        {isPositive ? `+₹${totalReturn}` : `-₹${totalReturn}`}
                      </Typography> */}
                    </Box>
                  </Box>
                </Paper>
              );
            })}
          </List>
        </>
      )}
    </Box>
  );
}
