import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { ThemeContext } from '../../theme-context';
import Cookies from 'js-cookie'; 
import './Sidebar.css'; 
import logo from '../../Assests/SmartCapital.png';
import DashboardIcon from '@mui/icons-material/Dashboard'; 
import TrendingUpIcon from '@mui/icons-material/TrendingUp';  
import ListAltIcon from '@mui/icons-material/ListAlt'; 
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'; 
import PersonIcon from '@mui/icons-material/Person'; 
import ExitToAppIcon from '@mui/icons-material/ExitToApp';  
import AssignmentIcon from '@mui/icons-material/Assignment'; 

const Sidebar = ({ isOpen, onToggle }) => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation(); 
  const sidebarRef = useRef(null);

  const [activePath, setActivePath] = useState(location.pathname);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onToggle();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onToggle]);

  const handleNavigation = (path, isLogout = false) => {
    if (isLogout) {
      Cookies.remove('userToken');
      Cookies.remove('userId');
      navigate('/');
    } else {
      navigate(path);
    }
    onToggle();
  };

  const isActive = (path) => activePath === path;  

  return (
    <div className={`sidebar ${theme} ${isOpen ? 'open' : ''}`} ref={sidebarRef}>
      <div className="sidebar-header">
        <img src={logo} alt="Logo" className="sidebar-logo" />
      </div>
      <ul className="sidebar-menu">
        <li
          className={`sidebar-item ${isActive('/Dashboard') ? 'active' : ''}`}
          onClick={() => handleNavigation('/Dashboard')}
        >
          <DashboardIcon sx={{ fontSize: '24px', marginRight: '8px' }} /> 
          Dashboard
        </li>
        <li
          className={`sidebar-item ${isActive('/StockTable') ? 'active' : ''}`}
          onClick={() => handleNavigation('/StockTable')}
        >
          <TrendingUpIcon sx={{ fontSize: '24px', marginRight: '8px' }} /> 
          Stock
        </li>
        <li
          className={`sidebar-item ${isActive('/Watchlist') ? 'active' : ''}`}
          onClick={() => handleNavigation('/Watchlist')}
        >
          <ListAltIcon sx={{ fontSize: '24px', marginRight: '8px' }} />
          Watchlist
        </li>
        <li
          className={`sidebar-item ${isActive('/wallet') ? 'active' : ''}`}
          onClick={() => handleNavigation('/wallet')}
        >
          <AccountBalanceWalletIcon sx={{ fontSize: '24px', marginRight: '8px' }} />
          Wallets
        </li>

        {/* Account Section */}
        <div className="sidebar-section">Account</div>

        <li
          className={`sidebar-item ${isActive('/Profile') ? 'active' : ''}`}
          onClick={() => handleNavigation('/Profile')}
        >
          <PersonIcon sx={{ fontSize: '24px', marginRight: '8px' }} />
          Profile
        </li>

        <li
          className={`sidebar-item ${isActive('/Portfolio') ? 'active' : ''}`}
          onClick={() => handleNavigation('/Portfolio')}
        >
          <AssignmentIcon sx={{ fontSize: '24px', marginRight: '8px' }} /> 
          Portfolio
        </li>

        <li
          className={`sidebar-item ${isActive('/logout') ? 'active' : ''}`}
          onClick={() => handleNavigation('/logout', true)}
        >
          <ExitToAppIcon sx={{ fontSize: '24px', marginRight: '8px' }} />
          Logout
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
