import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Box, Button, Card, CardContent, List, Typography, Paper, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemeContext } from '../../theme-context';
import { useWebSocketData } from '../../Context/WebSocketContext';
import FnoTabContent from './FnoTabContent';
import config from '../../config';
import NoHoldingsIllustration from '../../Assests/holding.png';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import OrderHistory from './Order';

const StyledCard = styled(Card)(({ theme, isDark }) => ({
  backgroundColor: isDark ? 'rgb(34, 34, 34)' : 'rgb(236, 255, 236)',
  border: '1px solid rgba(0, 0, 0, 0.06)',
  borderRadius: theme.spacing(1.5),
  boxShadow: 'none',
  '& .MuiCardContent-root': {
    padding: theme.spacing(1.5),
  },
}));

const ValueText = styled(Typography)(({ isDark }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: isDark ? '#fff' : '#333',
  lineHeight: 1.2,
  marginTop: '2px',
}));

const LabelText = styled(Typography)(({ isDark }) => ({
  fontSize: '0.75rem',
  color: isDark ? 'rgb(153, 153, 153)' : 'rgb(102, 102, 102)',
  fontWeight: 400,
}));

const ReturnText = styled(Typography)(({ color = 'rgb(22, 163, 74)', isDark }) => ({
  color: isDark ? '#66bb6a' : color,
  fontSize: '0.75rem',
  fontWeight: 500,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

export default function Portfolio() {
  const [tabValue, setTabValue] = useState(0);
  const [holdings, setHoldings] = useState([]);
  const { theme } = useContext(ThemeContext);
  const { socketData } = useWebSocketData();
  const [companyDetails, setCompanyDetails] = useState({});
  const isDark = theme === 'dark';
  const navigate = useNavigate();
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false); 

  const fetchPortfolio = async () => {
    setIsLoading(true);  
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');

      const response = await axios.get(`${config.apiBaseUrl}/order/portfolio`, {
        headers: {
          'x-access-token': token,
          'user-id': userId,
          'trade-type': 'equity',
        },
      });

      if (response.data.portfolio) {
        setHoldings(response.data.portfolio);
        setTotalInvestedAmount(response.data.totalInvestedAmount);
      }
    } catch (error) {
      console.error('Error fetching portfolio:', error);
    } finally {
      setIsLoading(false);  
    }
  };

  const fetchCompanyDetails = async (instrument_token, stockType) => {
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
      const response = await axios.get(apiUrl, {
        headers: {
          'instrument-token': instrument_token,
          'market-type': stockType,
        },
      });

      if (response.status === 200 && response.data) {
        setCompanyDetails((prevState) => ({
          ...prevState,
          [instrument_token]: { lastPrice: response.data.last_price || 0 },
        }));
      }
    } catch (error) {
      console.error('Error fetching company details. Please try again.', error);
    }
  };

  useEffect(() => {
    fetchPortfolio();
  }, []);

  useEffect(() => {
    if (holdings.length > 0) {
      holdings.forEach((holding) => {
        fetchCompanyDetails(holding.instrument_token, holding.stockType);
      });
    }
  }, [holdings]);

  const getLastPrice = (instrument_token) => {
    const socketPrice = socketData[instrument_token]?.last_price;
    const apiPrice = companyDetails[instrument_token]?.lastPrice;
    return socketPrice || apiPrice || 0;
  };

  const activeHoldings = holdings.filter((holding) => holding.isActive === true);
  const inactiveHoldings = holdings.filter((holding) => holding.isActive === false);

  const computePortfolioValues = () => {
    let totalReturn = 0;
    let totalCurrentValue = 0;

    activeHoldings.forEach((holding) => {
      const lastPrice = getLastPrice(holding.instrument_token);
      const totalValue = holding.quantity * lastPrice;
      const holdingReturn = totalValue - holding.investedAmount;

      totalReturn += holdingReturn;
      totalCurrentValue += totalValue;
    });

    return { totalReturn, totalCurrentValue };
  };

  const { totalReturn, totalCurrentValue } = computePortfolioValues();

  const sellStock = async (instrument_token, symbol, quantity, price, stockType) => {
    try {
      const token = Cookies.get('userToken');
      const userId = Cookies.get('userId');

      const response = await axios.post(
        `${config.apiBaseUrl}/order/sell`,
        {
          instrument_token,
          symbol,
          quantity,
          price,
          tradeType: 'equity',
          stockType,
          userId: userId,
          orderType: 'market',
        },
        {
          headers: {
            'x-access-token': token,
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          title: 'Success!',
          text: 'Stock sold successfully.',
          icon: 'success',
          confirmButtonText: 'Okay',
        });

        fetchPortfolio();
      } else {
        Swal.fire({
          title: 'Error',
          text: response.data.message || 'Something went wrong!',
          icon: 'error',
          confirmButtonText: 'Okay',
        });
      }
    } catch (error) {
      console.error('Error selling stock:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to sell stock. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Okay',
      });
    }
  };

  return (
    <Box sx={{ maxWidth: '100%', margin: '0 auto', p: 2, minHeight: '600px', backgroundColor: isDark ? '#121212' : '#fff' }}>
      <Box sx={{ mb: 3, display: 'flex', gap: 1, justifyContent: 'flex-start', role: 'tablist' }}>
        <Button
          variant={tabValue === 1 ? 'contained' : 'outlined'}
          onClick={() => setTabValue(0)}
          aria-selected={tabValue === 0}
          aria-controls="simple-tabpanel-0"
          role="tab"
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            fontWeight: 500,
            color: isDark ? (tabValue === 0 ? 'white' : 'rgba(255, 255, 255, 0.87)') : (tabValue === 0 ? 'white' : 'rgba(0, 0, 0, 0.87)'),
            backgroundColor: tabValue === 0 ? 'rgb(0, 102, 255)' : 'transparent',
            borderColor: isDark ? (tabValue === 0 ? 'white' : 'rgba(255, 255, 255, 0.87)') : 'rgba(0, 0, 0, 0.12)',
            '&:hover': {
              backgroundColor: tabValue === 0 ? 'rgb(0, 102, 255)' : 'transparent',
              borderColor: isDark ? 'white' : 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          Holdings
        </Button>
        <Button
          variant={tabValue === 1 ? 'contained' : 'outlined'}
          onClick={() => setTabValue(1)}
          aria-selected={tabValue === 1}
          aria-controls="simple-tabpanel-1"
          role="tab"
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            fontWeight: 500,
            color: isDark ? (tabValue === 1 ? 'white' : 'rgba(255, 255, 255, 0.87)') : (tabValue === 1 ? 'white' : 'rgba(0, 0, 0, 0.87)'),
            backgroundColor: tabValue === 1 ? 'rgb(0, 102, 255)' : 'transparent',
            borderColor: isDark ? (tabValue === 1 ? 'white' : 'rgba(255, 255, 255, 0.87)') : 'rgba(0, 0, 0, 0.12)',
            '&:hover': {
              backgroundColor: tabValue === 1 ? 'rgb(0, 102, 255)' : 'transparent',
              borderColor: isDark ? 'white' : 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          F&O
        </Button>
        <Button
          variant={tabValue === 2 ? 'contained' : 'outlined'}
          onClick={() => setTabValue(2)}
          aria-selected={tabValue === 2}
          aria-controls="simple-tabpanel-2"
          role="tab"
          sx={{
            borderRadius: '20px',
            textTransform: 'none',
            fontWeight: 500,
            color: isDark ? (tabValue === 2 ? 'white' : 'rgba(255, 255, 255, 0.87)') : (tabValue === 2 ? 'white' : 'rgba(0, 0, 0, 0.87)'),
            backgroundColor: tabValue === 2 ? 'rgb(0, 102, 255)' : 'transparent',
            borderColor: isDark ? (tabValue === 2 ? 'white' : 'rgba(255, 255, 255, 0.87)') : 'rgba(0, 0, 0, 0.12)',
            '&:hover': {
              backgroundColor: tabValue === 2 ? 'rgb(0, 102, 255)' : 'transparent',
              borderColor: isDark ? 'white' : 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          Orders
        </Button>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
          <CircularProgress size={50} sx={{ color: isDark ? '#fff' : '#000' }} />
        </Box>
      ) : (
        <>
          {/* Holdings Tab */}
          <TabPanel value={tabValue} index={0}>
            {activeHoldings.length > 0 ? (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 4 }}>
                <StyledCard
                  isDark={isDark}
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '150px',
                    padding: '16px',
                  }}
                >
                  <CardContent sx={{ position: 'relative', height: '100%' }}>
                    {/* Top Left */}
                    <LabelText isDark={isDark} sx={{ position: 'absolute', top: 10, left: 10 }}>
                      Invested
                    </LabelText>
                    <ValueText isDark={isDark} sx={{ position: 'absolute', top: 30, left: 10 }}>
                      ₹{totalInvestedAmount.toFixed(2)}
                    </ValueText>

                    {/* Top Right */}
                    <LabelText isDark={isDark} sx={{ position: 'absolute', top: 10, right: 10 }}>
                      Current
                    </LabelText>
                    <ValueText isDark={isDark} sx={{ position: 'absolute', top: 30, right: 10 }}>
                      ₹{totalCurrentValue.toFixed(2)}
                    </ValueText>

                    {/* Bottom Left */}
                    <LabelText isDark={isDark} sx={{ position: 'absolute', bottom: 65, left: 10 }}>
                      Total return
                    </LabelText>
                    <ValueText isDark={isDark} sx={{ position: 'absolute', bottom: 35, left: 10 }}>
                      ₹{totalReturn.toFixed(2)}
                    </ValueText>

                    {/* Bottom Right */}
                    <LabelText isDark={isDark} sx={{ position: 'absolute', bottom: 65, right: 10 }}>
                      Today's return
                    </LabelText>
                    <ValueText isDark={isDark} sx={{ position: 'absolute', bottom: 35, right: 10 }}>
                      ₹0 
                    </ValueText>

                  </CardContent>
                </StyledCard>
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', my: 4 }}>
                <img src={NoHoldingsIllustration} alt="No Holdings" style={{ width: '80%', maxWidth: '400px' }} />
              </Box>
            )}

            {activeHoldings.length > 0 && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 1.5,
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    color: isDark ? '#fff' : '#333',
                  }}
                >
                  Holdings ({activeHoldings.length})
                </Typography>
                <List sx={{ p: 0 }}>
                  {activeHoldings.map((holding, index) => {
                    const lastPrice = getLastPrice(holding.instrument_token);  
                    const totalValue = holding.quantity * lastPrice;  
                    const totalReturn = totalValue - holding.investedAmount;  

                    return (
                      <Paper
                        key={index}
                        elevation={0}
                        sx={{
                          border: '1px solid rgba(0, 0, 0, 0.06)',
                          borderRadius: 1.5,
                          mb: 1.5,
                          p: 2,
                          backgroundColor: isDark ? '#333' : '#fff',
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, alignItems: 'center' }}>
                          <Typography
                            onClick={() => navigate(`/StockChart/${holding.instrument_token}/${holding.stockType}`)} 
                            sx={{
                              color: isDark ? 'green' : 'green',
                              fontSize: '1rem',
                              fontWeight: 500,
                              cursor: 'pointer',  
                            }}
                          >
                            {holding.symbol}
                          </Typography>
                          <IconButton
                            onClick={() =>
                              sellStock(
                                holding.instrument_token,
                                holding.symbol,
                                holding.quantity,
                                lastPrice,
                                holding.stockType
                              )
                            }
                            color="secondary"
                            sx={{ ml: 1 }}
                          >
                            <ExitToAppIcon />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr auto',
                            gap: 1,
                            '& .MuiTypography-root': {
                              fontSize: '0.875rem',
                              lineHeight: 1.5,
                              color: isDark ? '#ccc' : 'rgb(102, 102, 102)',
                            },
                          }}
                        >
                          <Box>
                            <Typography>Invested ₹{holding.investedAmount}</Typography>
                            <Typography>Qty. {holding.quantity}</Typography>
                          </Box>
                          <Box sx={{ textAlign: 'right' }}>
                            <Typography 
                              sx={{
                                color: totalReturn > 0 ? 'green' : 'red', 
                                fontSize: '0.875rem',
                                fontWeight: 500,
                              }}
                            >
                              Total Return ₹{totalReturn > 0 ? `+${totalReturn.toFixed(2)}` : totalReturn.toFixed(2)}
                            </Typography>
                            <Typography>Avg. ₹{holding.avgPrice}</Typography>
                            <Typography>₹{lastPrice} (Mkt. Price)</Typography>
                          </Box>
                        </Box>
                      </Paper>
                    );
                  })}
                </List>
              </>
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <FnoTabContent />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <OrderHistory />
          </TabPanel>
        </>
      )}
    </Box>
  );
}
