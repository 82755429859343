import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Grid } from "@mui/material";
import { Category, LocationOn, Language } from "@mui/icons-material";
import { ThemeContext } from "../../theme-context";
import { useParams } from "react-router-dom";
import config from "../../config";

export default function StockDetailsProfile() {
  const { theme } = useContext(ThemeContext);
  const [error, setError] = useState(null);
  const { instrumentToken , exchange } = useParams();
  const [companyDetails, setCompanyDetails] = useState({ name: "", exchange: "", tradingsymbol: "", lot_size: 0 });
  const [isExpanded, setIsExpanded] = useState(false);
  const [depthData, setDepthData] = useState({ buy: [], sell: [] });

  const colors = {
    primary: {
      400: `var(--primary-${theme}-400)`,
      500: `var(--primary-${theme}-500)`,
    },
    greenAccent: {
      500: `var(--greenAccent-${theme}-500)`,
      600: `var(--greenAccent-${theme}-600)`,
    },
    grey: {
      100: `var(--grey-${theme}-100)`,
      300: `var(--grey-${theme}-300)`,
      900: `var(--grey-${theme}-900)`,
    },
    white: theme === 'dark' ? '#ffffff' : '#000000',
  };

  const fetchCompanyDetails = async () => {
    try {
      const apiUrl = `${config.apiBaseUrl}/stock/getStockByInstrumentToken`;
      const response = await axios.get(apiUrl, {
        headers: {
          "instrument-token": instrumentToken,
          "market-type": exchange,
        },
      });

      if (response.status === 200 && response.data) {
        setCompanyDetails({
          name: response.data.name || "NA",
          exchange: response.data.exchange || "NA",
          tradingsymbol: response.data.tradingsymbol || "NA",
          lot_size: response.data.lot_size || 0,
          longBusinessSummary: response.data.company?.longBusinessSummary || "NA",
          website: response.data.company?.website || "NA",
          industry: response.data.company?.industry || "NA",
          upper_circuit_limit: response.data.upper_circuit_limit || "NA",
          lower_circuit_limit: response.data.lower_circuit_limit || "NA",
          address: {
            address1: response.data.company?.address1 || "NA",
            address2: response.data.company?.address2 || "NA",
            city: response.data.company?.city || "NA",
            country: response.data.company?.country || "NA",
            zip: response.data.company?.zip || "NA",
          },
          OHLC: {
            open: response.data.ohlc?.open || "NA",
            low: response.data.ohlc?.low || "NA",
            close: response.data.ohlc?.close || "NA",
            high: response.data.ohlc?.high || "NA",
          },
        });

        setDepthData(response.data.depth || { buy: [], sell: [] });
      } else {
        setError("Unexpected data format or empty response from the server.");
      }
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.status} - ${error.response.statusText}`);
      }  else {
        setError("Error fetching company details. Please try again.");
      }
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, [ instrumentToken, exchange]);

  const { buy, sell } = depthData;

  const totalBuyQty = depthData.buy.reduce((acc, order) => acc + order.quantity, 0);
  const totalSellQty = depthData.sell.reduce((acc, order) => acc + order.quantity, 0);
  const totalQty = totalBuyQty + totalSellQty;
  const buyPercentage = totalQty === 0 ? 0 : (totalBuyQty / totalQty) * 100;
  const sellPercentage = totalQty === 0 ? 0 : (totalSellQty / totalQty) * 100;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  if (error) {
    return (
      <div style={{minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme === "dark" ? "#121212" : "#f5f5f5", }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Card style={{ backgroundColor: "transparent", color: theme === "dark" ? "#fff" : "#000", boxShadow: 'none' }}>
            <Typography variant="h5" style={{ display: "flex", alignItems: "center", marginTop: '10px', marginLeft: '10px', fontSize: '18px' }}>
              COMPANY PROFILE
            </Typography>
            <CardContent style={{ paddingTop: "5px" }}>
            {companyDetails?.industry && (
              <dl style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", gap: "16px" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "16px", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100px" }}>
                    <Category style={{ marginBottom: "8px", height: "100px" }} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "4px", fontSize: '14px' }}>
                      Sector
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: '14px' }}>
                      {companyDetails?.industry}
                    </Typography>
                  </div>
                </div>
                <div style={{ borderLeft: "1px solid #D1D5DB", height: "70px", alignSelf: "center", marginTop: "15px" }} />
              </dl>
            )}

            {companyDetails?.marketCap && (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography variant="subtitle1" style={{ display: "flex", alignItems: "center", marginBottom: "4px", fontSize: '14px' }}>
                  <span style={{ marginRight: "8px", fontWeight: "bold" }}>Market Cap</span>
                </Typography>
                <Typography variant="body1" style={{ fontWeight: "normal", fontSize: '14px' }}>
                  {companyDetails?.marketCap}
                </Typography>
              </div>
            )}

            {companyDetails?.longBusinessSummary && (
              <div style={{ marginTop: "16px" }}>
                <Typography
                  variant="body1"
                  style={{fontSize: '14px', display: '-webkit-box', WebkitLineClamp: isExpanded ? 'none' : 7, WebkitBoxOrient: 'vertical',overflow: 'hidden',textOverflow: 'ellipsis',}}
                >
                  {companyDetails?.longBusinessSummary}
                </Typography>

                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleToggle}>
                  {isExpanded ? ' ...See less' : ' ...See more'}
                </span>
              </div>
            )}

            {companyDetails?.address && (
              <div style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
                <LocationOn style={{ marginRight: "8px", width: "20px", height: "20px" }} />
                <Typography variant="subtitle1" style={{ fontWeight: "bold", marginRight: "8px", fontSize: '14px' }}>
                  Address:
                </Typography>
              </div>
            )}

            {companyDetails?.address ? (
              <Typography variant="body1" style={{ fontWeight: "normal", marginTop: "8px", fontSize: '14px' }}>
                {companyDetails?.address?.address1}, {companyDetails?.address?.address2}, {companyDetails?.address?.city}, {companyDetails?.address?.country} {companyDetails?.address?.zip}
              </Typography>
            ) : (
              <Typography variant="body1" style={{ fontWeight: "normal", marginTop: "8px", fontSize: '14px' }}>
                No details available
              </Typography>
            )}

            {companyDetails?.website && (
              <div style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
                <Language style={{ marginRight: "8px", width: "20px", height: "20px" }} />
                <Typography variant="subtitle1" style={{ fontWeight: "bold", marginRight: "8px", fontSize: '14px' }}>
                  Website:
                </Typography>
                <Typography variant="body1" style={{ fontWeight: "normal", fontSize: '14px' }}>
                  <a
                    href={companyDetails.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: theme === "dark" ? "#1e90ff" : "#007bff", textDecoration: "none" }}
                  >
                    {companyDetails.website}
                  </a>
                </Typography>
              </div>
            )}

            {companyDetails?.lower_circuit_limit && companyDetails?.upper_circuit_limit && (
              <div style={{ marginTop: "16px" }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '48%' }}>
                    <Typography variant="body2" fontSize="small" sx={{ marginBottom: '4px' }}>
                      Lower Circuit
                    </Typography>
                    <Typography variant="body2" fontSize="small">
                      {companyDetails.lower_circuit_limit}
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '48%' }}>
                    <Typography variant="body2" fontSize="small" sx={{ marginBottom: '4px' }}>
                      Upper Circuit
                    </Typography>
                    <Typography variant="body2" fontSize="small">
                      {companyDetails.upper_circuit_limit}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{display: 'flex',height: 8,borderRadius: 5,background: 'linear-gradient(to right, yellow 50%, yellow 50%)',overflow: 'hidden'}}
                >
                  <Box sx={{ width: '10%', background: 'yellow', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }} />
                  <Box sx={{ width: '15%', background: 'yellow', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }} />
                </Box>
              </div>
            )}

            {companyDetails?.OHLC && (
              <div style={{ marginTop: "16px", display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "16px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "4px", fontSize: '14px' }}>
                    Open
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '14px' }}>
                    ₹{companyDetails?.OHLC?.open || "N/A"}
                  </Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "4px", fontSize: '14px' }}>
                    High
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '14px' }}>
                    ₹{companyDetails?.OHLC?.high || "N/A"}
                  </Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "4px", fontSize: '14px' }}>
                    Low
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '14px' }}>
                    ₹{companyDetails?.OHLC?.low || "N/A"}
                  </Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", marginBottom: "4px", fontSize: '14px' }}>
                    Close
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '14px' }}>
                    ₹{companyDetails?.OHLC?.close || "N/A"}
                  </Typography>
                </div>
              </div>
            )}
          </CardContent>
          </Card>
        </Grid>

        {/* Market Depth Data Card */}
        <Grid item xs={12} md={6}>
          <Card style={{ backgroundColor: theme === "dark" ? "#181818" : "#edebeb", color: theme === "dark" ? "#fff" : "#000" }}>
            <Typography variant="h5" style={{ display: "flex", alignItems: "center", marginTop: '10px', marginLeft: '10px' }}>
              <span style={{ color: "green", fontWeight: "bold", marginRight: "8px" }}>Buy</span>
              <span style={{ color: theme === "dark" ? "white" : "black", fontWeight: "bold", marginLeft: "8px", marginRight: "8px" }}>&</span>
              <span style={{ color: "red", fontWeight: "bold", marginLeft: "8px" }}>Sell</span>
            </Typography>

            <CardContent style={{ paddingTop: "24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <Table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", fontWeight: "bold", padding: "8px", textAlign: "left", border: 'none' }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", fontWeight: "bold", padding: "8px", textAlign: "left", border: 'none' }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", fontWeight: "bold", padding: "8px", textAlign: "left", border: 'none' }}>
                          Orders
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {buy.map((order, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: theme === "light" ? "green" : "green", position: 'relative', lineHeight: '1', border: 'none' }}>
                            {order.price}
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? "green" : "green", position: 'relative', lineHeight: '1', border: 'none' }}>
                            {order.quantity}
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? "green" : "green", position: 'relative', lineHeight: '1', border: 'none' }}>
                            {order.orders}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", fontWeight: "bold", padding: "8px", textAlign: "left", border: 'none' }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", fontWeight: "bold", padding: "8px", textAlign: "left", border: 'none' }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ color: theme === "light" ? colors.grey[500] : "white", fontWeight: "bold", padding: "8px", textAlign: "left", border: 'none' }}>
                          Orders
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sell.map((order, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: theme === "light" ? "red" : "red", position: 'relative', lineHeight: '1', border: 'none' }}>
                            {order.price}
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? "red" : "red", position: 'relative', lineHeight: '1', border: 'none' }}>
                            {order.quantity}
                          </TableCell>
                          <TableCell sx={{ color: theme === "light" ? "red" : "red", position: 'relative', lineHeight: '1', border: 'none' }}>
                            {order.orders}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          
          <div style={{ width: '100%', marginTop: '16px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '48%' }}>
                    <Typography
                      variant="body2"
                      fontSize="small"
                      sx={{
                        marginBottom: '4px',
                        color: theme === 'dark' ? 'white' : 'black',  
                      }}
                    >
                      Total Buy Qty
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize="small"
                      sx={{
                        color: theme === 'dark' ? 'white' : 'black',  
                      }}
                    >
                      {buyPercentage.toFixed(2)}% ({totalBuyQty})
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '48%' }}>
                    <Typography
                      variant="body2"
                      fontSize="small"
                      sx={{
                        marginBottom: '4px',
                        color: theme === 'dark' ? 'white' : 'black', 
                      }}
                    >
                      Total Sell Qty
                    </Typography>
                    <Typography
                      variant="body2"
                      fontSize="small"
                      sx={{
                        color: theme === 'dark' ? 'white' : 'black',  
                      }}
                    >
                      {sellPercentage.toFixed(2)}% ({totalSellQty})
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', height: 8, borderRadius: 5, background: '#e0e0e0', overflow: 'hidden' }}>
                  <Box
                    sx={{
                      width: `${buyPercentage}%`,
                      background: 'green',
                      borderTopLeftRadius: '5px',
                      borderBottomLeftRadius: '5px',
                    }}
                  />
                  <Box
                    sx={{
                      width: `${sellPercentage}%`,
                      background: 'red',
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

